import React from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Service = () => {
  return (
    <article className="wrapper">
      <NavBar current="service"/>
      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <div className="cards w-full">
          <h1 className="text-2xl">Dienstleistungen</h1>

          <p className="mt-4">
            Sie haben das geeignete Fahrzeug schon gefunden, dann ist es nicht mehr weit bis zu ihrer Finanzierung.
          </p>

          <p>Durch die Finanzierungsmöglichkeiten Leasing ermöglichen wir Ihnen Ihren Autowunsch Wirklichkeit werden zu
            lassen.
          </p>

          <ul className="px-4 lg:px-0">
            <li className="mt-4 flex items-center">
              <i className="fas fa-check-circle mr-2"/>
              <span>
              Die Nutzung des Fahrzeuges, nicht das Eigentum steht im Mittelpunkt. Dadurch bezahlen Sie beim Leasing nur
              den
              Wertverlust während der Laufzeit und nicht die gesamten Anschaffungskosten!
              </span>
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-check-circle mr-2"/>
              <span>
              Die monatliche Zahlung wird individuell auf Ihre finanziellen Möglichkeiten abgestimmt. Sie selbst bestimmen
              die Höhe der Leasingrate durch die Laufzeit, die Kilometer, die Sie jedes Jahr mit Ihrem Auto zurück legen
              oder
              durch eine Vorauszahlung.</span>
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-check-circle mr-2"/>
              <span>Wir kümmern uns um die komplette Abwicklung.</span>
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-check-circle mr-2"/>
              <span>Hohe Flexibilität. Wir passen Ihre Bedürfnisse dem Vertrag an.</span>
            </li>

          </ul>
        </div>

        <br/>

        <div className="cards w-full">
          <h2 className="text-xl">Wir bieten außerdem</h2>

          <ul className="px-4 lg:px-0">
            <li className="mt-4 flex items-center">
              <i className="fas fa-arrow-circle-right mr-2"/>
              Beratung die unabhängig von der Automarke stattfindet. Wir sind an keine Automobilhersteller gebunden. Was
              wir zu Ihrem Vorteil ausnutzen können.
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-arrow-circle-right mr-2"/>
              Realistische Restwerte
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-arrow-circle-right mr-2"/>
              Angebote die auf dem Markt absolut konkurrenzfähig sind.
            </li>
            <li className="mt-4 flex items-center">
              <i className="fas fa-arrow-circle-right mr-2"/>
              Zügige und kundenfreundliche Abwicklung.
            </li>
          </ul>
        </div>

      </div>
      <Footer/>
    </article>
  )
    ;
};

export default Service;